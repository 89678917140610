import { createMuiTheme } from '@material-ui/core/styles';
import createTheme from 'react-storefront/theme/createTheme';
const defaultTheme = createMuiTheme();

const theme = createTheme({
	name: 'newport',
	palette: {
		primary: {
			themeColor: '#000000',
			background: '#fff',
			color: '#212121',
			text: '#212121',
			main: '#12284C',
			dark: '#01011d',
			light: '#16375f',
			darker: '#0e2d51',
			lighter: '#16375f',
			backgroundContrast: '#f0f0f0',
			purchase: {
				background: '#041e3a',
				hover: '#01011d'
			},
			disabled: '#999',
			highlight: '#fff',
			lowlight: '#333',
			altTitle: '#11284a',
			special: '#ca0d0d',
			blue: '#11284a'
		},
		secondary: {
			main: '#24b47e',
			text: '#fff'
		},
		error: {
			main: '#fb5252',
			text: '#fff'
		},
		header: {
			background: '#fff',
			backgroundContrast: '#f0f0f0',
			color: '#000',
			altColor: '#666',
			icons: {
				color: '#000',
				badge: {
					color: '#fff',
					background: '#000',
				},
			},
			menu: {
				background: '#fff',
				backgroundContrast: '#eaeaea',
				highlight: 'transparent',
				color: '#222',
				items: {
					level0: '#222',
					level1: '#222',
					level2: '#222',
					level3: '#888',
				},
				altColor: '#888',
				arrows: '#333'
			},
			search: {
				color: '#212121',
				background: 'transparent'
			}
		},
		footer: {
			color: '#fff',
		},
		background: {
			body: '#ffffff',
			element: '#ffffff'
		},
		text: {
			black: '#333',
			grey: '#444'
		},
	},
	typography: {
		fontFamily: '"Futura", "Helvetica", "Arial", sans-serif',
		fontWeight: 300,
		headerFont: 'Optima, serif',
		h1: {
			fontFamily: '"Times New Roman", serif',
			fontSize: 32,
			fontWeight: 400
		},
		h2: {
			fontFamily: '"Times New Roman", serif',
			fontSize: 28,
			fontWeight: 400
		},
		h3: {
			fontFamily: '"Times New Roman", serif',
			fontSize: 24,
			fontWeight: 300
		},
		h4: {
			fontFamily: '"Open Sans", "Helvetica", "Arial", sans-serif',
		},
		h5: {
			fontFamily: '"Open Sans", "Helvetica", "Arial", sans-serif',
		},
		h6: {
			fontFamily: '"Open Sans", "Helvetica", "Arial", sans-serif',
		},
		subtitle1: {
			fontFamily: '"Open Sans", "Helvetica", "Arial", sans-serif',
		},
		subtitle2: {
			fontFamily: '"Open Sans", "Helvetica", "Arial", sans-serif',
		},
		body1: {
			fontFamily: '"Futura", "Helvetica", "Arial", sans-serif',
		},
		body2: {
			fontFamily: '"Futura", "Helvetica", "Arial", sans-serif',
		},
		button: {
			fontFamily: '"Futura", "Helvetica", "Arial", sans-serif',
		},
		productPrice: {
			fontFamily: '"Futura", serif',
			fontSize: 18,
			fontWeight: 400
		},
		dropdown: {
			fontFamily: '"Futura", "Helvetica", "Arial", sans-serif',
			fontSize: 14,
			fontWeight: 400,
			color: '#333',
			textTransform: 'none'
		},
		caption: {
			fontFamily: '"Futura", "Helvetica", "Arial", sans-serif',
		},
		overline: {
			fontFamily: '"Futura", "Helvetica", "Arial", sans-serif',
		},
		menu: {
			serif: {
				fontFamily: '"Times New Roman", serif',
				fontSize: '12px',
				letterSpacing: '0.05em'
			},
			sansSerif: {
			}
		},
	},
	cmsContent: {
		color: 'white',
		'& h1': {
			fontFamily: 'Chronicle, serif',
			fontSize: 32,
			fontWeight: 400,
			color: '#212121',
			lineHeight: 1.2,
			margin: 0,
			marginBottom: 10
		},
		'& h2': {
			margin: 0,
			fontFamily: 'Chronicle, serif',
			fontSize: 22,
			fontWeight: 400,
			textAlign: 'center',
			color: '#000',
			lineHeight: 1.2,
		},
		'& h3': {
			margin: 0,
			fontFamily: 'Chronicle, serif',
			fontSize: 18,
			lineHeight: 1.25,
			fontWeight: 400,
			color: '#333'
		},
		'& h4': {
			margin: 0,
			fontFamily: 'Chronicle, serif',
			fontSize: 16,
			fontWeight: 400,
			color: '#333'
		},
		'& a': {
			color: 'inherit'
		},
		'& .font-fenice': {
			fontFamily: 'Chronicle, serif',
    		fontWeight: 400,
		},
		'& .font-didot': {
			fontFamily: 'Chronicle, serif',
    		fontWeight: 600,
		},
		'& .font-lucia': {
			fontFamily: 'Lucia, serif',
    		fontWeight: 400,
			position: 'relative',
			zIndex: 10,
			textShadow: '0px 0px 0.5px black',
			lineHeight: 1.25
		},
		'& .font-red-bright': {
			fontFamily: 'Red Bright, serif',
			fontSize: 80,
    		fontWeight: 400,
		},
		'& .pre-heading': {
			display: 'none',
			color: '#fff',
			fontSize: 13,
			textAlign: 'center',
			fontWeight: 500,
			marginBottom: 5,
			letterSpacing: 2.25,
			textTransform: 'uppercase'
		},
		'& .pre-heading-christmas': {
			display: 'none',
			fontFamily: '"Great Vibes", serif',
			fontSize: '28px',
			letterSpacing: '3.5px',
			color: '#fff',
			textTransform: 'none',
			lineHeight: '18px',
			textAlign: 'center'
		},
		'& .pre-heading--thin': {
			display: 'none',
			marginBottom: 20,
			fontSize: 16,
			letterSpacing: 2.25,
			color: '#fff',
			fontWeight: 300,
			textAlign: 'center'
		},
		'& p': {
			marginTop: 0,
			marginBottom: 5,
			fontSize: '1.2rem'
		},
		'& img': {
			maxWidth: '100%'
		},
		'& ul': {
			paddingLeft: 0,
			listStylePosition: 'inside',
			fontSize: 14
		},
		'& ul li': {
			fontSize: 'inherit',
			color: 'inherit'
		},
		'& h1:first-child, & h2:first-child': {
			marginTop: 0
		},
		'& .show-mob': {
			display: 'none !important'
		},
		'& .hide-mob': {
			display: 'block !important'
		},
		'& .btn-primary': {
			display: 'inline-block',
			padding: '15px 30px 15px 30px',
			fontSize: 14,
			fontWeight: 600,
			color: '#fff',
			borderRadius: 0,
			lineHeight: '30px',
			letterSpacing: '0.5px',
			textDecoration: 'none',
			background: '#42BDCB',
			'&.btn-sm': {
				padding: '12px 24px 12px 24px',
				fontSize: 12,
				fontWeight: 400,
				lineHeight: '22px',
			}
		},
		'& .btn-secondary': {
			display: 'inline-block',
			padding: '12px 30px 12px 30px',
			fontSize: 14,
			fontWeight: 500,
			color: '#fff',
			borderRadius: 0,
			letterSpacing: '0.5px',
			textDecoration: 'none',
			background: '#212121',
			'&.btn-sm': {
				padding: '12px 24px 12px 24px',
				fontSize: 12,
				fontWeight: 400,
				lineHeight: '22px',
			}
		},
		'& .btn-bordered': {
			display: 'inline-block',
			padding: '15px 45px 15px 45px',
			fontSize: 14,
			fontWeight: 600,
			color: '#fff',
			border: '2px solid #fff',
			borderRadius: 0,
			letterSpacing: '0.5px',
			textDecoration: 'none',
			background: 'transparent',
			'&.light-bg': {
				color: '#212121',
				border: '2px solid #212121'
			},
			'&.btn-sm': {
				padding: '12px 24px 12px 24px',
				fontSize: 12,
				fontWeight: 400,
				lineHeight: '20px',
			}
		},
		'& iframe': {
			maxWidth: '100%'
		},
		['@media screen and (max-width: 720px)']: {
			'& .btn-bordered': {
				padding: '15px 30px 15px 30px'
			},
			'& .btn-primary, & .btn-secondary': {
				fontWeight: 400,
				letterSpacing: 0
			},
			'& .show-mob': {
				display: 'block !important'
			},
			'& .hide-mob': {
				display: 'none !important'
			},
			'& .pre-heading': {
				fontSize: 14,
				marginBottom: -5
			},
			'& .pre-heading-christmas': {
				fontSize: '20px'
			},
			'& .pre-heading--thin': {
				letterSpacing: 1,
				marginBottom: -5
			}
		}
	},
	cmsContentMobile: {
		'& h1': {
			fontSize: '28px !important'
		},
		'& .pre-heading': {
			display: 'block'
		},
		'& .text': {
			display: 'none'
		}
	},
	breakpoints: {
		values: {
			xs: 0,
			sm: 720,
			md: 1024,
			lg: 1600,
			xl: 1800
		}
	},
	spacing: [0, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60],
	maxWidth: '1600px',
	logo: {
		url: `/images/logo/newport-logo-standard.png`,
		greyUrl: '/images/logo/newport-logo-grey.png',
		alt: 'Newport Collection'
	},
	footerLogo: {
		url: `/images/logo/newport-logo-standard.png`,
		greyUrl: '/images/logo/newport-logo-grey.png',
		alt: 'Newport Collection'
	},
	icons: {
		search: '/images/icons/search.svg',
		cart: '/images/icons/basket.svg'
	},
	boxShadow: {
		0: 'none',
		1: '0 0px 2px rgba(0,0,0,0.02), 0 1px 4px rgba(0,0,0,0.02), 0 2px 6px rgba(0,0,0,0.02), 0 4px 8px rgba(0,0,0,0.02)',
		2: '0 0px 3px rgba(0,0,0,0.03), 2px 2px 6px rgba(0,0,0,0.03), 4px 4px 9px rgba(0,0,0,0.03), 4px 4px 16px rgba(0,0,0,0.03)',
		3: '0 0px 4px rgba(0,0,0,0.04), 3px 3px 8px rgba(0,0,0,0.04), 5px 6px 12px rgba(0,0,0,0.04), 5px 5px 24px rgba(0,0,0,0.04)',
		4: '0 0px 5px rgba(0,0,0,0.05), 4px 4px 10px rgba(0,0,0,0.05), 6px 8px 15px rgba(0,0,0,0.05), 6px 6px 32px rgba(0,0,0,0.05)',
		5: '0 0px 6px rgba(0,0,0,0.06), 5px 5px 12px rgba(0,0,0,0.06), 7px 10px 18px rgba(0,0,0,0.06), 7px 7px 48px rgba(0,0,0,0.06)',
		6: '0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)',
		7: '0px 4px 5px -2px rgba(0,0,0,0.2),0px 7px 10px 1px rgba(0,0,0,0.14),0px 2px 16px 1px rgba(0,0,0,0.12)',
		8: '0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)',
		9: '0px 5px 6px -3px rgba(0,0,0,0.2),0px 9px 12px 1px rgba(0,0,0,0.14),0px 3px 16px 2px rgba(0,0,0,0.12)',
		10: '0px 6px 6px -3px rgba(0,0,0,0.2),0px 10px 14px 1px rgba(0,0,0,0.14),0px 4px 18px 3px rgba(0,0,0,0.12)',
		11: '0px 6px 7px -4px rgba(0,0,0,0.2),0px 11px 15px 1px rgba(0,0,0,0.14),0px 4px 20px 3px rgba(0,0,0,0.12)',
		12: '0px 7px 8px -4px rgba(0,0,0,0.2),0px 12px 17px 2px rgba(0,0,0,0.14),0px 5px 22px 4px rgba(0,0,0,0.12)',
		13: '0px 7px 8px -4px rgba(0,0,0,0.2),0px 13px 19px 2px rgba(0,0,0,0.14),0px 5px 24px 4px rgba(0,0,0,0.12)',
		14: '0px 7px 9px -4px rgba(0,0,0,0.2),0px 14px 21px 2px rgba(0,0,0,0.14),0px 5px 26px 4px rgba(0,0,0,0.12)',
		15: '0px 8px 9px -5px rgba(0,0,0,0.2),0px 15px 22px 2px rgba(0,0,0,0.14),0px 6px 28px 5px rgba(0,0,0,0.12)',
		16: '0px 8px 10px -5px rgba(0,0,0,0.2),0px 16px 24px 2px rgba(0,0,0,0.14),0px 6px 30px 5px rgba(0,0,0,0.12)',
		17: '0px 8px 11px -5px rgba(0,0,0,0.2),0px 17px 26px 2px rgba(0,0,0,0.14),0px 6px 32px 5px rgba(0,0,0,0.12)',
		18: '0px 9px 11px -5px rgba(0,0,0,0.2),0px 18px 28px 2px rgba(0,0,0,0.14),0px 7px 34px 6px rgba(0,0,0,0.12)',
		19: '0px 9px 12px -6px rgba(0,0,0,0.2),0px 19px 29px 2px rgba(0,0,0,0.14),0px 7px 36px 6px rgba(0,0,0,0.12)',
		20: '0px 10px 13px -6px rgba(0,0,0,0.2),0px 20px 31px 3px rgba(0,0,0,0.14),0px 8px 38px 7px rgba(0,0,0,0.12)',
		dropdown: '0 1px 3px rgb(50 50 93 / 5%)',
		dropdownActive: '3px 3px 10px rgb(50 50 93 / 10%)'
	},
	borderShadow: '0 1px 0 rgb(0 0 0 / 15%), 0 0 1px 0 rgb(0 0 0 / 15%)',
	drawerWidth: '90vw',
	button: {
		primary: {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			padding: '12px 24px',
			backgroundColor: 'transparent',
			border: 'none'
		},
		purchase: {
			padding: '20px 8px',
			flexGrow: 1,
			color: 'white',
			fontSize: 15,
			fontWeight: 600,
			lineHeight: '16px',
			textTransform: 'uppercase',
			letterSpacing: 2,
			backgroundColor: "#041e3a",
			borderRadius: 0,
		},
		purchaseHover: {
			backgroundColor: "#01011d !important"
		},
		purchasePsuedo: {
			background: '#fff',
		},
		purchaseDisabled: {
			background: '#000',
		},
	},
	overrides: {
		gutters: {
			[defaultTheme.breakpoints.up('sm')]: {
				paddingLeft: '20px',
				paddingRight: '20px',
			},
		},
	}
});

export default theme;