import React, { useState, useEffect, useMemo, useContext } from 'react';
import SessionContext from './sessionContext';
import fetch from 'react-storefront/fetch';
import get from 'lodash/get';
import {i18n} from "../../../tools/i18n";
import Cookies from 'js-cookie';
import {useRouter} from "next/router";
import { ErrorContext } from "../error";
import myNewportClient from '../../../tools/myNewportClient';

const initialState = {
	token: false,
	cart: {
		extensions: {},
		lineItems: [],
		errors: {},
		loading: true,
		price: {
			totalPrice: 0
		}
	},
	salesChannel: {
		translated: {
			customFields: {
				toplinks_outlet_text: '',
				toplinks_outlet_link: '',
				toplinks_campaign_text: '',
				toplinks_campaign_link: '',
				toplinks_customerservice_text: '',
				toplinks_customerservice_link: ''
			}
		}
	},
	currentLanguage: {},
	currency: {},
	config: {
		language: {
			locale: "se",
			languageCode: 'sv-SE'
		},
		currency: 'SEK',
		loop54Feed: "https://newport-se-dev.54proxy.com"
	},
	geoIpCountry: false,
	countries: [],
	filters: process.env.NEXT_PUBLIC_STOREFRONT_FILTER_ATTRIBUTES ? process.env.NEXT_PUBLIC_STOREFRONT_FILTER_ATTRIBUTES.split(',') : [],
};

/**
 * Fetches user session data from a specific URL and provides it to descendant components via `SessionContext`.
 *
 * User and session data such as the number of items in the cart, the user's name, and email should always be
 * fetched when the app mounts, not in `getInitialProps`, otherwise the SSR result would not be cacheable
 * since it would contain user-specific data.
 */
export default function SessionProvider({url, config, children}) {
	const router = useRouter();
	const [session, setSession] = useState({...initialState, config: config});
	const [wishlistProducts, setWishlistProducts] = useState([]);
	const [wishlists, setWishlists] = useState([]);
	const [weddingList, setWeddingList] = useState(false);
	const [shippingMethods, setShippingMethods] = useState([]);
	const [preparedCoupon, setPreparedCoupon] = useState(typeof localStorage !== 'undefined' ? localStorage.getItem('preparedCoupon') || '' : '');
	const [isLoggedIn, setIsLoggedIn] = useState(false);
	const [isInitial, setIsInitial] = useState(true);
	const [isLoading, setIsLoading] = useState(false);
	const [isSaleOngoing, setIsSaleOngoing] = useState(false);
	const {actions: errorActions} = useContext(ErrorContext);

	useEffect(() => {
		function isSaleOngoing() {
			let now = new Date();
			let saleStart = new Date('Nov 17 2024 00:00:00');
			let saleEnd = new Date('Nov 19, 2024 23:59:59');
			return now >= saleStart && now <= saleEnd;
		}

		setIsSaleOngoing(isSaleOngoing());
	}, [router.asPath])

	useEffect(async () => {
		if(context.cart?.lineItems?.length > 0 && preparedCoupon && preparedCoupon !== '') {
			let shouldApply = context.cart?.extensions['cart-promotions']?.addedCodes.indexOf(preparedCoupon) < 0;

			if(shouldApply) {
				await context.actions.applyCoupon(preparedCoupon);
			}
		}
	}, [session.cart]);

	useEffect(() => {
		if(typeof localStorage !== 'undefined') {
			localStorage.setItem('preparedCoupon', preparedCoupon ? preparedCoupon : '');
		}
	}, [preparedCoupon]);

	/**
	 * Wishlist
	 */
	useEffect(async () => {
		if(isLoggedIn && config.myNewport) {
			await fetchWishlists();
			await fetchWeddingList();
			await updateAccount();
		}
	}, [isLoggedIn]);

	useEffect(async () => {
		if(wishlists.length > 0) {
			await fetchWishlistProducts();
		}

		if(weddingList) {
			await fetchWeddingList();
		}
	}, [wishlists]);

	async function updateAccount() {
		const discoveryKey = localStorage.getItem('apptus.session') ? JSON.parse(localStorage.getItem('apptus.session')).customerKey : false;
		if (discoveryKey) {
			await myNewportClient.updateDiscoveryKey(config.myNewport, discoveryKey);
		}
	}

	async function fetchWishlists() {
		let wishlists = await myNewportClient.getWishlists(config.myNewport);

		if(wishlists.status === 'success' && wishlists.data) {
			setWishlists(wishlists.data);
		}
	}

	async function fetchWeddingList() {
		let wishlists = await myNewportClient.getWeddingList(config.myNewport);

		if(wishlists.status === 'success' && wishlists.data) {
			setWeddingList(wishlists.data);
		}
	}

	async function fetchWishlistProducts () {
		let newWishlistProducts = await myNewportClient.getWishlistProductIds(config.myNewport);

		setWishlistProducts(newWishlistProducts);
	}

	async function productExistInCart(product) {
		let productExist = false;

		await context.actions.getCart(undefined, true);

		session.cart.lineItems.forEach((item) => {
			if(item.referencedId === product) {
				productExist = item;
			}
		});

		return productExist;
	}

	function checkCartForErrors(errors) {
		let errorKeys = Object.keys(errors);
		let errorFound = false;
		let errorKey = '';

		errorKeys.forEach((key) => {
			if (errors[key].level > 0) {
				errorFound = key;
				errorKey = key;
			}
		});

		return {
			found: errorFound ? true : false,
			key: errorKey,
			message: errorFound ? 'Promotion not found' : undefined
		}
	}

	function getAddedProductNumber(cart, productId) {
		let addedProductNumber = '';
		cart.lineItems.forEach((item) => {
			if(item.referencedId === productId) {
				let productNumber = item.payload.productNumber;
				addedProductNumber = productNumber;
			}
		});

		return addedProductNumber;
	}

	function saveCouponCodesWhenEmpty(couponCode) {
		setPreparedCoupon(couponCode);
	}

	function getAppliedCouponCodes(cart) {
		return cart?.extensions?.['cart-promotions']?.addedCodes || session?.cart?.extensions?.['cart-promotions']?.addedCodes || [];
	}

	async function trackAddedCouponCodes(cart) {
		let appliedCoupons = getAppliedCouponCodes(cart);

		await fetch('/api/tracking/custom-fields', {
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
			},
			credentials: 'include',
			body: JSON.stringify({customFields: { applied_coupons: appliedCoupons }})
		});
	}

	const context = useMemo(() => {
		return {
			...session,
			shippingMethods,
			loggedIn: isLoggedIn,
			wishlists,
			wishlistProducts,
			weddingList,
			isSaleOngoing,
			loading: isLoading,
			actions: {
				/**
				 * Get countries
				 */
				async getCountries() {
					if(session.countries.length > 0) {
						return;
					}

					const response = await fetch('/api/countries/get', {
						method: 'get',
						headers: {
							'Content-Type': 'application/json',
						},
						credentials: 'include'
					});

					const result = await response.json();

					if (result.status === 'success') {

						setSession((newSession) => {
							return {...newSession, countries: result.countries}
						});
					} else {
						throw new Error(
							get(
								result,
								'error',
								'An unknown error occurred while attempting to fetch countries.',
							),
						);
					}
				},

				/**
				 * Change country
				 */
				async changeCountry(country) {
					const response = await fetch('/api/countries/set', {
						method: 'post',
						headers: {
							'Content-Type': 'application/json',
						},
						credentials: 'include',
						body: JSON.stringify({
							country
						}),
					});

					const result = await response.json();

					if (result.status === 'success') {
						await fetchSession();
						await this.getCart();
					} else {
						throw new Error(
							get(
								result,
								'error',
								'An unknown error occurred while attempting to update country.',
							),
						);
					}
				},

				isSalesChannelNordic() {
					return config.language.locale === 'sv'
						|| config.language.locale === 'nb'
						|| config.language.locale === 'dk'
						|| config.language.locale === 'fi';
				},

				getAppliedCouponCodes() {
					return getAppliedCouponCodes(this.cart);
				},

				/**
				 * Get the cart. Use this function to get the cart
				 */
				async getCart(isCheckout = false, keepLoading = false) {
					let headers = {
						'Content-Type': 'application/json',
						'Cache-Control': 'no-cache',
						'Pragma': 'no-cache',
						'Expires': '0',
					}

					if(router.components['/checkout'] || isCheckout) {
						headers['sw-is-checkout'] = true;
					}

					setIsLoading(true);

					const response = await fetch('/api/cart/get', {
						method: 'get',
						headers: headers,
						credentials: 'include'
					});

					const result = await response.json();

					if (result.status === 'success') {
						setSession((newSession) => {
							return {...newSession, cart: {...result.cart, loading: keepLoading}};
						});
						setIsLoading(false);
					} else {
						setIsLoading(false);
						throw new Error(
							get(
								result,
								'error',
								'An unknown error occurred while attempting to fetch your cart.',
							),
						);
					}
				},

				/**
				 * Get full cart discount
				 */
				getCartDiscount() {
					let items = session.cart.lineItems;

					if(items.length === 0) {
						return 0;
					}

					let discountTotal = 0;
					items.forEach((item) => {
						const price = item.price;
						const qty = item.quantity;

						if(price.listPrice) {
							discountTotal += qty * Math.abs(price.listPrice.discount);
						}
					});

					return discountTotal;
				},

				/**
				 * Adds items to the cart
				 * @param {Object} product Product data object
				 * @param {Number} quantity The quantity to add to the cart
				 * @param {Object} otherParams Additional data to submit to api/addToCart
				 */
				async addToCart({product, quantity}) {
					let cartItem = await productExistInCart(product);

					if(cartItem) {
						await this.updateCart({item: cartItem.id, quantity: cartItem.quantity + quantity});

						let productNumber = getAddedProductNumber(session.cart, product);

						// Trigger event listeners to show header and open cart
						document.dispatchEvent(new CustomEvent("add-to-cart", {
								detail: productNumber
						}));
					} else {
						setIsLoading(true);

						const response = await fetch('/api/cart/add', {
							method: 'post',
							headers: {
								'Content-Type': 'application/json',
							},
							credentials: 'include',
							body: JSON.stringify({
								product,
								quantity: parseInt(quantity)
							}),
						});

						const result = await response.json();

						if (result.status === 'success') {
							setSession((newSession) => {
								return {...newSession, cart: {...result.cart, loading: isLoading}};
							});

							let productNumber = getAddedProductNumber(result.cart, product);

							// Trigger event listeners to show header and open cart
							document.dispatchEvent(new CustomEvent("add-to-cart", {
								detail: productNumber
							}));

							setIsLoading(false);
						} else {
							throw new Error(
								get(
									result,
									'error',
									'An unknown error occurred while attempting to add the item to your cart.',
								),
							);

							setIsLoading(false);
						}
					}
				},

				/**
				 * Apply coupon
				 * @param {String} Coupon code string
				 */
				async applyCoupon(code, email = '') {
					const response = await fetch('/api/cart/applyCoupon', {
						method: 'post',
						headers: {
							'Content-Type': 'application/json',
						},
						credentials: 'include',
						body: JSON.stringify({
							code: code.toUpperCase(),
							email: email
						}),
					});

					const result = await response.json();

					if (result.status === 'success') {
						let errorKeys = Object.keys(result.cart.errors);

						if(result.cart.errors && errorKeys.indexOf('promotion-not-found') >= 0) {
							if (preparedCoupon === code) {
								console.log(preparedCoupon, code);
								setPreparedCoupon(false);
								return result.cart.errors;
							}
						}

						if(result.cart) {
							if(result?.cart?.lineItems.length === 0) {
								saveCouponCodesWhenEmpty(code);
							}

							setSession((newSession) => {
								return {...newSession, cart: result.cart};
							});

							await trackAddedCouponCodes(result.cart);

							return result.cart.errors;
						}
					} else {
						if(result.status === 'missing-email') {
							return {
								'email-required': {
									key: 'email-required',
									level: 5
								}
							};
						} else if(result.status === 'promotion-not-found' || result.status === 'promotion-redeemed') {
							return result.cart.errors;
						} else {
							throw new Error(
								get(
									result,
									'error',
									'An unknown error occurred while attempting to apply coupon code.',
								),
							);
						}
					}
				},

				/**
				 * Apply giftcard
				 * @param {String} Giftcard string
				 */
				async applyGiftcard(code) {
					const response = await fetch('/api/cart/applyGiftcard', {
						method: 'post',
						headers: {
							'Content-Type': 'application/json',
						},
						credentials: 'include',
						body: JSON.stringify({
							code: code
						}),
					});

					const result = await response.json();

					if (result.status === 'success') {
						await context.actions.getCart();
						return result.data;
					} else {
						return result.data;
					}
				},

				/**
				 * Remove giftcard
				 * @param {String} Giftcard string
				 */
				async removeGiftcard(code) {
					const response = await fetch('/api/cart/applyGiftcard', {
						method: 'delete',
						headers: {
							'Content-Type': 'application/json',
						},
						credentials: 'include',
						body: JSON.stringify({
							code: code
						}),
					});

					const result = await response.json();

					if (result.status === 'success') {
						await context.actions.getCart();
						return result.data;
					} else {
						return result.data;
					}
				},

				/**
				 * Updates the items in the cart. Use this function to update the quantity of a product
				 * in the cart or remove a product from the cart.
				 * @param {Object} item Cart item to be updated
				 * @param {number} quantity Expected quantity value
				 * @param {Object} otherParams Additional data to submit to api/cart/update
				 */
				async updateCart({item, quantity, isCheckout = false}) {
					let headers = {
						'Content-Type': 'application/json',
						'Cache-Control': 'no-cache',
						'Pragma': 'no-cache',
						'Expires': '0',
					}

					if(router.components['/checkout'] || isCheckout) {
						headers['sw-is-checkout'] = true;
					}

					setIsLoading(true);

					const response = await fetch('/api/cart/update', {
						method: 'post',
						headers: headers,
						credentials: 'include',
						body: JSON.stringify({
							item,
							quantity: parseInt(quantity)
						}),
					});

					const result = await response.json();

					if (result.status === 'success') {
						setSession((newSession) => {
							return {...newSession, cart: {...result.cart, loading: isLoading}};
						});

						setIsLoading(false);

						// Trigger event listeners to track cart updates
						document.dispatchEvent(new CustomEvent("has-updated-cart"));
					} else {
						setIsLoading(false);
						throw new Error(
							get(
								result,
								'error',
								'An unknown error occurred while attempting to add the item to your cart.',
							),
						);
					}
				},

				/**
				 * Removes item in the cart.
				 * @param {Object} item Cart item to be updated
				 * @param {Object} otherParams Additional data to submit to /api/cart/remove
				 */
				async removeCartItem({item, isCheckout = false, code = false}) {
					let headers = {
						'Content-Type': 'application/json',
						'Cache-Control': 'no-cache',
						'Pragma': 'no-cache',
						'Expires': '0',
					}

					if(router.components['/checkout'] || isCheckout) {
						headers['sw-is-checkout'] = true;
					}

					setIsLoading(true);

					const response = await fetch('/api/cart/remove', {
						method: 'post',
						headers: {
							'Content-Type': 'application/json',
						},
						credentials: 'include',
						body: JSON.stringify({
							item
						}),
					});

					const result = await response.json();

					if (result.status === 'success') {
						if(code && preparedCoupon === code) {
							setPreparedCoupon('');
						}

						setSession((newSession) => {
							return {...newSession, cart: result.cart};
						});

						if(code) {
							await trackAddedCouponCodes(result.cart);
						}

						setIsLoading(false);

						// Trigger event listeners to track cart item removals
						document.dispatchEvent(new CustomEvent("remove-from-cart"));
					} else {
						setIsLoading(false);
						throw new Error(
							get(
								result,
								'error',
								'An unknown error occurred while attempting to add the item to your cart.',
							),
						);
					}
				},

				/**
				 * REgister customer used with Adyen checkout
				 * @param customerData
				 * @returns {Promise<boolean|*>}
				 */
				async registerCustomer(customerData) {
					const response = await fetch('/api/session/register', {
						method: 'post',
						headers: {
							'Content-Type': 'application/json',
						},
						credentials: 'include',
						body: JSON.stringify(customerData),
					});

					const result = await response.json();

					if (result.status === 'success') {
						let accessToken = result.accessToken;

						await fetchSession(accessToken);
						await this.getCart();

						return result.customer;
					} else {
						throw new Error(
							get(
								result,
								'error',
								'An unknown error occurred while attempting register customer.',
							),
						);
					}
				},

				/**
				 * Update customer used with Adyen checkout
				 * @param customerData
				 * @returns {Promise<boolean|*>}
				 */
				async updateCustomer(email, customer, billingAddressId, billingAddress, shippingAddressId, shippingAddress) {
					const response = await fetch('/api/session/update', {
						method: 'post',
						headers: {
							'Content-Type': 'application/json',
						},
						credentials: 'include',
						body: JSON.stringify({email, customer, billingAddressId, billingAddress, shippingAddressId, shippingAddress}),
					});

					const result = await response.json();

					if (result.status === 'success') {
						await fetchSession();
						return result.customer;
					} else {
						throw new Error(
							get(
								result,
								'error',
								'An unknown error occurred while attempting register customer.',
							),
						);
					}
				},

				async getShippingMethods() {
					let getShippingMethodsEndpoint = '/api/checkout/shipping-methods';

					try {
						let requestData = await fetch(getShippingMethodsEndpoint, {
							headers: {
								'Content-Type': 'application/json'
							}
						});

						let shippingMethodsData = await requestData.json();

						shippingMethodsData.sort((a, b) => {
							if(a.position > b.position) {
								return 1;
							} else if(a.position < b.position) {
								return -1;
							} else {
								return 0;
							}
						});

						setShippingMethods(shippingMethodsData);
					} catch (e) {
						console.log(e);
					}
				},

				async setContextCountry(countryId) {
					if(!countryId) {
						return;
					}

					try {
						let response = await fetch('/api/checkout/set-context-country', {
							method: 'POST',
							headers: {
								'Content-Type': 'application/json'
							},
							body: JSON.stringify({
								countryId
							})
						});

						console.log(response);

						await fetchSession();
						await this.getCart();
					} catch (e) {
						console.log(e);
					}
				},

				async setShippingMethod(shippingMethodId) {
					let getSetShippingMethodsEndpoint = '/api/checkout/set-shipping-method';

					setIsLoading(true);

					try {
						let requestData = await fetch(getSetShippingMethodsEndpoint, {
							method: 'POST',
							headers: {
								'Content-Type': 'application/json'
							},
							body: JSON.stringify({
								shippingMethodId
							})
						});

						let shippingMethodsData = await requestData.json();

						if(shippingMethodsData.status !== 'success') {
							console.log('Set shipping method error');
						}

						await Promise.all([fetchSession(), this.getCart()]);
						setIsLoading(false);
					} catch (e) {
						console.log(e);
						setIsLoading(false);
					}
				},

				async changeShippingMethodIfUnavailable() {
					let shippingMethodExists = shippingMethods.filter((shippingMethod) => {
						return shippingMethod.id === session.shippingMethodId;
					});

					if(shippingMethodExists.length === 0 && shippingMethods.length > 0) {
						await this.setShippingMethod(shippingMethods[0].id);
					}
				},

				getEclubData() {
					return localStorage.getItem('eclubData') ? JSON.parse(localStorage.getItem('eclubData')) : false;
				},

				/**
				 * Wishlist functions
				 */
				async addOrRemoveProductLike(productId, wishlistId, action) {
					let updatedWishlist, newWishlists;

					switch (action) {
						case 'add':
							updatedWishlist = await myNewportClient.addWishlistProduct(config.myNewport, wishlistId, productId);

							if(updatedWishlist.status === 'success') {
								newWishlists = wishlists.map((wishlist) => {
									if (updatedWishlist.data.id === wishlist.id) {
										wishlist = updatedWishlist.data;
									}

									return wishlist;
								});

								setWishlists(newWishlists);
							}

							break;
						case 'remove':
							updatedWishlist = await myNewportClient.removeWishlistProduct(config.myNewport, wishlistId, productId);

							if(updatedWishlist.status === 'success') {
								newWishlists = wishlists.map((wishlist) => {
									if (updatedWishlist.data.id === wishlist.id) {
										wishlist = updatedWishlist.data;
									}

									return wishlist;
								});

								setWishlists(newWishlists);
							}

							break;
					}
				},

				async createNewWishlist({ name, type = 'standard', text, location, date }) {
					let response = await myNewportClient.createWishlist(config.myNewport, {
						name,
						type,
						text,
						location,
						date
					});

					if(response.status === 'success') {
						let newWishlists = [...wishlists];
						newWishlists.push(response.data);

						setWishlists(newWishlists);
					}

					return response;
				}
			},
		};
	}, [session, isLoading, isLoggedIn, wishlists, wishlistProducts, weddingList, shippingMethods]);

	async function fetchSession(cartRecoveryCookie = false) {
		let sessionApiUrl = url;

		if(cartRecoveryCookie) {
			sessionApiUrl = sessionApiUrl + '?cartRecovery=' + cartRecoveryCookie;
		}

		const response = await fetch(sessionApiUrl, {
			method: "get",
			credentials: 'include',
			headers: {
				'Cache-Control': 'no-cache',
			  	'Pragma': 'no-cache',
			  	'Expires': '0',
			}
		});

		const result = await response.json();

		setSession((newSession) => {
			return {...newSession, ...result};
		});
	}

	function getCartRecoveryCookie() {
		let cartRecoveryToken = router.query ? router.query.cartRecovery : false;

		if(cartRecoveryToken) {
			let previousSessionToken = Cookies.get(process.env.NEXT_PUBLIC_STOREFRONT_SESSION_COOKIE);

			if(previousSessionToken !== cartRecoveryToken) {
				// Trigger event listeners to track cart updates
				document.dispatchEvent(new CustomEvent("cart-recovered", {
					detail: {
						previousSessionToken: previousSessionToken
					}
				}));
			}

			return cartRecoveryToken;
		}

		return false;
	}

	async function applyCouponCodeFromUrl(couponCode) {
		let eClubData = localStorage.getItem('eclubData') ? JSON.parse(localStorage.getItem('eclubData')) : false;
		let error = false;

		if (eClubData) {
			error = await context.actions.applyCoupon(couponCode, eClubData.email);
		} else {
			error = await context.actions.applyCoupon(couponCode);
		}

		let errors = checkCartForErrors(error);

		if(error.status !== 'missing-email' && !errors.found) {
			// Trigger success message
			errorActions.addError({
				type: 'success',
				messageKey: 'discount-code-success',
				messageData: {
					code: couponCode
				}
			});
		}
	}

	function checkLoginStatus() {
		console.log(process.env.NEXT_PUBLIC_MYNEWPORT_SESSION_TOKEN, Cookies.get(process.env.NEXT_PUBLIC_MYNEWPORT_SESSION_TOKEN));
		return !!Cookies.get(process.env.NEXT_PUBLIC_MYNEWPORT_SESSION_TOKEN);
	}

	// Set initial language
	useEffect(() => {
		let language = session.config.language.locale;

		if(language && i18n.options.supportedLngs.indexOf(language) >= 0) {
			i18n.changeLanguage(language);
		}

		document.addEventListener("update-cart", (e) => {
			context.actions.getCart();
		});

		if(checkLoginStatus()) {
			setIsLoggedIn(true);
		}
	}, []);

	useEffect(async () => {
		if(session.cart) {
			setIsLoading(true);
			await context.actions.getShippingMethods();
			setIsLoading(false);
		}
	}, [session.cart]);

	// Get session
	useEffect(async () => {
		if (url) {
			await fetchSession(getCartRecoveryCookie());
			await context.actions.getCart();
		}
	}, [url]);

	// Get cart after session initiated
	useEffect(async () => {
		if(session.token && isInitial) {
			setIsInitial(false);
			await context.actions.getCart();
		}
	}, [session.token]);

	useEffect(async () => {
		if(session.token) {
			await context.actions.changeShippingMethodIfUnavailable();
		}
	}, [session, shippingMethods]);

	useEffect(async () => {
		const {
			query: {
				couponCode
			}
		} = router;

		if (session.token && couponCode) {
			document.addEventListener("eclub-login", () => {
				console.log('eclub-login');
				applyCouponCodeFromUrl(couponCode)
			});

			document.addEventListener("eclub-missing", () => {
				console.log('eclub-missing');
				applyCouponCodeFromUrl(couponCode)
			});
		}

		return () => {
			document.removeEventListener('eclub-login', applyCouponCodeFromUrl);
			document.removeEventListener('eclub-missing', applyCouponCodeFromUrl);
		}
	}, [session.token]);

	// Switch language in i18n if settings language changes
	useEffect(() => {
		let language = session.currentLanguage.code || session.config.language.locale;
		if(language && i18n.options.supportedLngs.indexOf(language) >= 0) {
			i18n.changeLanguage(language);
		}
	}, [session]);

	return <SessionContext.Provider value={context}>
		{children}
	</SessionContext.Provider>;
}
