import React, { useEffect, useState } from 'react';
import { Fab, Snackbar } from '@material-ui/core';
import { CloseOutlined } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import Slide from '@material-ui/core/Slide';
import ErrorContext from './errorContext';
import { useTranslation } from '../../../tools/i18n';
import { makeStyles } from '@material-ui/styles';
import theme from "../../theme";

const useStyles = makeStyles(
	{
		wrapper: {
			display: 'flex',
			flexDirection: 'column'
		},
		clearIcon: {
			'&.success': {
				color: 'rgba(0, 240, 0, 0.75)'
			},
			'&.info': {
				color: 'rgba(0, 0, 240, 0.75)'
			},
			'&.warning': {
				color: 'rgba(240, 120, 0, 0.75)'
			},
			'&.error': {
				color: 'rgba(240, 0, 0, 0.75)'
			}
		},
		message: {
			marginBottom: 10,
			'& .icon': {
				fontWeight: 800
			},
			'& .message': {
				fontWeight: 600
			},
			[theme.breakpoints.up('md')]: {
				transform: 'scale(1.25)'
			}
		},
		snackbarRoot: {
			bottom: 0,
			left: 0,
			right: 0,
			padding: 8,
			transform: 'none',
			'&:before': {
				content: '""',
    			position: 'absolute',
    			bottom: 0,
    			left: 0,
    			right: 0,
    			height: 200,
				zIndex: -1,
    			backgroundImage: 'linear-gradient(0deg, rgba(0,0,0,0.4), transparent)'
			}
		}
	},
	{ name: 'ErrorList' }
);

export default function ErrorProvider({ children }) {
	const [errors, setErrors] = useState([]);
	const [snackbar, setSnackbar] = useState(false);
	const { t } = useTranslation();
	const classes = useStyles();

	useEffect(() => {
		console.log(errors);
		if (errors.length > 0) {
			console.log(errors);
			setSnackbar(true);
		} else {
			setSnackbar(false);
		}
	}, [errors]);

	let context = {
		errors,
		actions: {
			addError: ({ type, messageKey, messageData }) => {
				setErrors(errors => {
					return [...errors, { type, messageKey, messageData }];
				});

				setTimeout(() => {
					removeLast();
				}, 10000);
			}
		}
	};

	function removeLast() {
		setErrors(errors => {
			errors.pop();

			return [...errors];
		});
	}

	function removeErrors(errorIndex) {
		let newErrors = errors.filter((error, index) => {
			return errorIndex !== index;
		});

		setErrors(newErrors);
	}

	function SlideTransition(props) {
		return <Slide {...props} direction={"up"} />
	}

	return (
		<ErrorContext.Provider value={context}>
			<Snackbar
				open={snackbar}
				classes={{root: snackbar ? classes.snackbarRoot : ''}}
				anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
				TransitionComponent={SlideTransition}
			>
				<div className={classes.wrapper}>
					{errors.map((error, key) => (
						<Alert
							classes={{icon: 'icon', message: 'message'}}
							severity={error.type}
							className={classes.message}
							key={key}
							action={
								<CloseOutlined
									className={[classes.clearIcon, error.type].join(' ')}
									style={{ cursor: 'pointer' }}
									onClick={() => {
										removeErrors(key);
									}}
								/>
							}
						>
							{t('errors:' + error.messageKey, { ...error.messageData })}
						</Alert>
					))}
				</div>
			</Snackbar>
			{children}
		</ErrorContext.Provider>
	);
}
